// src/templates/pages/SolutionsListing.js

import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import parse from 'html-react-parser'
import cx from 'classnames'
import Assemble from '../../animations/assemble'
import Mesh from '../../animations/mesh'
import Maze from '../../animations/maze'

function SolutionsListing({ data }) {
  const pageData = data.wpPage.template.solutionsPage

  return (
    <Layout>
      <Seo post={data.wpPage} />

      <section className="page-intro relative -mt-nav-cancel pt-nav-cancel">
        <div className="container px-responsive-xl-128">
          <div className="mb-responsive-xl-32 border-b border-granite-200 py-responsive-xl-80 text-center">
            {data.wpPage.title && (
              <h1 className="headline-xxl mb-responsive-xl-24">
                {parse(data.wpPage.title)}
              </h1>
            )}

            {pageData.description && (
              <p className="text-xl">{parse(pageData.description)}</p>
            )}
          </div>
        </div>
        <div className="page-intro-bg pointer-events-none absolute left-0 top-0 right-0 -z-10 h-[50vh] min-h-full bg-rings-right bg-cover bg-bottom"></div>
      </section>

      <section className="solution-listing">
        <div className="container">
          <ul className="space-y-responsive-xl-64 md:space-y-responsive-xl-128">
            {pageData.solutionAreas.map((node, index) => {
              const nodeData = node.template.solutionArea
              const title = nodeData.subheader
                ? nodeData.subheader
                : node.title

              // TODO: This is wildly fragile, just temporary for staging
              // purposes
              const animation = i => {
                if (i === 'assemble') {
                  return <Assemble />
                } else if (i === 'mesh') {
                  return <Mesh />
                } else if (i === 'maze') {
                  return <Maze />
                } else {
                  return <Mesh />
                }
              }

              return (
                <li
                  key={index}
                  className={cx(
                    'flex flex-col items-center justify-between text-center md:-mx-responsive-xl-48 md:flex-row md:text-left',
                    {
                      'md:flex-row-reverse': (index + 1) % 2 == 0,
                    }
                  )}
                >
                  <div className="mx-auto w-2/3 md:mx-responsive-xl-48 md:w-1/2">
                    {animation(nodeData.animation)}
                  </div>

                  <div className="solution-listing-text md:mx-responsive-xl-48 md:w-1/2">
                    {title && (
                      <h2 className="headline-md mb-responsive-xl-12">
                        {parse(title)}
                      </h2>
                    )}
                    <p className="mb-responsive-xl-24 font-book text-lg">
                      {parse(nodeData.summarydescription)}
                    </p>
                    {/* <Link to={node.uri}>Learn More</Link> */}
                    <Link to={node.uri} className="btn btn-primary">
                      Learn More
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default SolutionsListing

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        ... on WpTemplate_SolutionsListing {
          solutionsPage {
            description
            solutionAreas {
              ... on WpPage {
                title
                uri
                template {
                  ... on WpTemplate_SolutionArea {
                    solutionArea {
                      subheader
                      summarydescription
                      animation
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
